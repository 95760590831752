import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import axios from 'axios';
const ClosedOrders: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {

        const value = localStorage.getItem('authToken');
        console.log(value);
        const response = await axios.get(
          'https://melist.backendtropa.com.br//admin/order/served',
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + value },
          }
        );
        console.log(response.data)
        setOrders(response?.data)
      } catch (error: any) {
        console.log(error.toString())
      }
    };

    fetchData();
  }, []);

  return (
    <div className='pedidos'>
      <table>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Telefone</th>
            <th>Itens na lista</th>
            <th>Loja</th>
            <th>CEP</th>
            <th>Data do pedido</th>
            <th>Detalhes</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td>{order?.owner?.name}</td>
              <td>{order?.owner?.phone}</td>
              <td>{order?.items.length}</td>
              <td>{order?.market.name}</td>
              <td>{order?.owner?.address?.cep}</td>
              <td>{order.createdAt && new Date(order?.createdAt).toLocaleString()}</td>
              <td><a href={'/order-detail/'+order?.id} ><button type='button' className='detail'>Detalhe</button></a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClosedOrders;
