// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './layout/Layout';
import OpenOrders from './pages/OpenOrders';
import ClosedOrders from './pages/ClosedOrders';
import DetailOrder from './pages/DetailOrder';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Rota pública */}
          <Route path="/login" element={<Login />} />

          {/* Rota protegida */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/open-orders"
            element={
              <ProtectedRoute>
                <Layout>
                  <OpenOrders />
                </Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/closed-orders"
            element={
              <ProtectedRoute>
                <Layout>
                  <ClosedOrders/>
                </Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/order-detail/:id"
            element={
              <ProtectedRoute>
                <Layout>
                  <DetailOrder/>
                </Layout>
              </ProtectedRoute>
            }
          />

          {/* Redirecionamento para login caso rota seja desconhecida */}
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
