import React, { useState } from 'react';
import '../../styles/form/floatingInput.css';

interface FloatingInputProps {
  label: string;
  type: string;
  value: string;
  onChange: (value: string) => void;
}

const FloatingInput: React.FC<FloatingInputProps> = ({ label, type, value, onChange }) => {
  return (
    <div className="floating-input-container">
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="floating-input"
        required
      />
      <label className={`floating-label ${value ? 'filled' : ''}`}>{label}</label>
    </div>
  );
};

export default FloatingInput;
