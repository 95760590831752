import React from 'react';
import { useLocation } from 'react-router-dom'; // Importa o hook useLocation
import '../styles/header.css';

interface HeaderProps {
  activeUser: number;
  createdList: number;
  sharedList: number;
  totalProducts: number;
}

const Header: React.FC<HeaderProps> = ({
  activeUser,
  createdList,
  sharedList,
  totalProducts
}) => {
  const location = useLocation(); // Obtém a rota atual

  return (
    <header>
      <div className="container">
        <div className="logo">
          <img src="/images/logo.png" alt="Melist Logo" />
        </div>
        <ul className="menu">
          <li className={location.pathname === '/open-orders' ? 'active' : ''}>
            <a href="/open-orders">Pedidos Abertos</a>
          </li>
          <li className={location.pathname === '/closed-orders' ? 'active' : ''}>
            <a href="/closed-orders">Pedidos Atendidos</a>
          </li>
        </ul>
        <ul className="dash">
          <li>
            <span className="title">Usuários ativos</span>
            <span className="value">{activeUser}</span>
          </li>
          <li>
            <span className="title">Listas Criadas</span>
            <span className="value">{createdList}</span>
          </li>
          <li>
            <span className="title">Compartilhadas</span>
            <span className="value">{sharedList}</span>
          </li>
          <li>
            <span className="title">Base de produtos</span>
            <span className="value">{totalProducts}</span>
          </li>
        </ul>
        <div>
          <a href="/logout">
            <img src="/images/logout.png" alt="Logout" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
