import React from 'react';
import '../styles/dashboard.css';
const Dashboard: React.FC = () => {
  return (
    <div style={{textAlign:'center', marginTop:'30vh'}}>
      <h1>Bem vindo ao Painel Administrativo!</h1>
    </div>
  );
};

export default Dashboard;
