import React, { useEffect, useState } from 'react';
import '../styles/orders.css';
import axios from 'axios';

const OpenOrders: React.FC = () => {


  const [orders, setOrders] = useState<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {

        const value = localStorage.getItem('authToken');
        console.log(value);
        const response = await axios.get(
          'https://melist.backendtropa.com.br//admin/order',
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + value },
          }
        );
        console.log(response.data)
        setOrders(response?.data)
      } catch (error: any) {
        console.log(error.toString())
      }
    };

    fetchData();
  }, []);


  async function fetchData(){
    try {

      const value = localStorage.getItem('authToken');
      console.log(value);
      const response = await axios.get(
        'https://melist.backendtropa.com.br//admin/order',
        {
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + value },
        }
      );
      console.log(response.data)
      setOrders(response?.data)
    } catch (error: any) {
      console.log(error.toString())
    }
  }

  async function served(id:string){
    try {

      const value = localStorage.getItem('authToken');
      console.log(value);
      const response = await axios.get(
        'https://melist.backendtropa.com.br//admin/order/served/'+id,
        {
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + value },
        }
      );
      console.log(response.data)
      fetchData()
    } catch (error: any) {
      console.log(error.toString())
    }
  }

  return (
    <div className='pedidos'>
      <table>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Telefone</th>
            <th>Itens na lista</th>
            <th>Loja</th>
            <th>CEP</th>
            <th>Data do pedido</th>
            <th>Atendido</th>
            <th>Detalhes</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td>{order?.owner?.name}</td>
              <td>{order?.owner?.phone}</td>
              <td>{order?.items.length}</td>
              <td>{order?.market.name}</td>
              <td>{order?.owner?.address?.cep}</td>
              <td>{order.createdAt && new Date(order?.createdAt).toLocaleString()}</td>
              <td><button type='button' className='detail' onClick={() => served(order.id)}>Atendido</button></td>
              <td><a href={'/order-detail/'+order?.id}><button type='button' className='detail'>Detalhe</button></a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OpenOrders;
