// components/Layout.tsx
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import Header from './Header';
import axios from 'axios';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { authenticated } = useAuth();

  const [dashData, setDashData] = useState({
    activeUser:0,
    createdList:0,
    sharedList:0,
    totalProducts:0
  }) 

  useEffect(() => {
    const fetchData = async () => {
      try {

        const value = localStorage.getItem('authToken');
        console.log(value);
        const response = await axios.get(
          'https://melist.backendtropa.com.br//admin/dash',
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+value },
          }
        );
  
        setDashData(response?.data)
      } catch (error: any) {
        console.log(error.toString())
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {authenticated && (
        <Header
          activeUser={dashData.activeUser}
          createdList={dashData.createdList}
          sharedList={dashData.sharedList}
          totalProducts={dashData.totalProducts}
        />
      )}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
