// Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/login.css';
import FloatingInput from '../components/form/FloatingInput';
import axios from 'axios';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault(); // Impede o reload da página

    try {
      const response = await axios.post(
        'https://melist.backendtropa.com.br//auth/request-otp/admin',
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      const { token } = response.data;
      localStorage.setItem('authToken', token); // Salva o token no localStorage
      login(); // Chama a função de login do contexto
      alert('Login realizado com sucesso!');
      navigate('/open-orders'); // Redireciona para o Dashboard
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert('Erro no login: ' + (error.response?.data.msg || 'Erro desconhecido.'));
      } else {
        alert('Erro inesperado. Tente novamente.');
      }
    }
  };

  return (
    <div className="container-login">
      <div className="logo">
        <img src="/images/logo.png" alt="Melist Logo" />
      </div>
      <form onSubmit={handleLogin}>
        <h2>Faça login na sua conta</h2>
        <FloatingInput
          label="E-mail"
          type="email"
          value={email}
          onChange={setEmail}
        />
        <FloatingInput
          label="Senha"
          type="password"
          value={password}
          onChange={setPassword}
        />
        <button type="submit">Entrar</button>
      </form>
    </div>
  );
};

export default Login;
